<style lang="sass">
.dialog-dashboard-dialog
  position: absolute
  background: #fff
  height: 95vh
  width: 60vw
  top: 0
  right: 0

.dialog-dashboard-header
  padding: 1rem
  background: #383838

.dialog-dashboard-dashboard
  max-height: 100% !important
  min-height: 94.2%
  width: 100% !important
  overflow-y: scroll
</style>

<template>
  <div class="dialog-dashboard-dialog" v-if="localDialog">
    <div
      class="dialog-dashboard-header d-flex align-center justify-space-between"
    >
      <v-btn icon color="white" @click="localDialog = false"
        ><v-icon color="white">mdi-close</v-icon></v-btn
      >

      <span class="headline white--text">{{ silo.name }}</span>
    </div>

    <div class="dialog-dashboard-dashboard">
      <silo-list-compact-data
        v-if="siloId != null"
        :siloId="siloId"
        :hide-map="true"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SiloListCompactData from "../silos/SiloListCompactData.vue";
export default {
  name: "DialogDashboard",

  components: { SiloListCompactData },

  props: {
    dialog: {
      default: false,
      required: true,
    },

    siloId: {
      default: null,
    },
  },

  model: { prop: "dialog" },

  data() {
    return {
      silo: {},
    };
  },

  computed: {
    ...mapGetters("silos", ["getSiloById"]),

    localDialog: {
      get() {
        return this.dialog;
      },

      set(val) {
        this.$emit("input", val);
      },
    },
  },

  methods: {},

  async created() {
    if (this.siloId) this.silo = this.getSiloById(this.siloId);
  },

  watch: {
    siloId(val) {
      if (val) this.silo = this.getSiloById(val);
    },
  },
};
</script>