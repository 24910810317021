<template>
  <v-main>
    <gmap-map
      :center="center"
      :zoom="7"
      map-type-id="terrain"
      style="width: 100vw; min-height: 94.5vh; max-width: 90vw"
    >
      <gmap-cluster @click="clickedCluster">
        <gmap-marker
          :position="{ lat: silo.lat, lng: silo.lng }"
          @click="clickedMarker(silo)"
          :icon="{
            url: getSiloMarkerColor(silo.siloId),
            scaledSize: { height: 40, width: 40 },
            silo: silo,
          }"
          v-for="silo in silos"
          :key="silo.siloId"
        />
      </gmap-cluster>
    </gmap-map>

    <dialog-dashboard v-model="dashboardDialog" :siloId="siloDashboardId" />

    <v-dialog v-model="chooseSiloDialog" max-width="600">
      <v-card>
        <v-card-title>{{ $t("silos.chooseSilo") }}</v-card-title>
        <v-card-text>
          <div
            class="siloChooseRow d-flex align-center justify-space-between"
            v-for="silo in silosTochoose"
            :key="silo.siloId"
          >
            {{ silo.name }}
            <v-btn color="primary" @click="chooseSilo(silo)">{{
              $t("common.choose")
            }}</v-btn>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="gray" text @click="chooseSiloDialog = false">{{
            $t("common.close")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-main>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { components } from "gmap-vue";
import DialogDashboard from "./DialogDashboard.vue";

export default {
  name: "HomeDashboard",

  components: {
    "gmap-cluster": components.Cluster,
    DialogDashboard,
  },

  data() {
    return {
      silosTochoose: [],
      siloDashboardId: null,
      chooseSiloDialog: false,
      dashboardDialog: false,

      center: {
        lat: 59.27412,
        lng: 15.2066,
      },
    };
  },

  methods: {
    ...mapActions("silos", ["getSilos"]),
    ...mapActions("alarms", ["getAlarmActives"]),

    chooseSilo(silo) {
      this.siloDashboardId = silo.siloId;
      this.chooseSiloDialog = false;
      this.dashboardDialog = true;
    },

    clickedMarker(silo) {
      this.siloDashboardId = silo.siloId;
      this.dashboardDialog = true;
    },

    clickedCluster(cluster) {
      this.silosTochoose = cluster.markers.map((d) => {
        return { name: d.icon.silo.name, siloId: d.icon.silo.siloId };
      });

      // Display dialog to choose which silo we want to choose
      this.chooseSiloDialog = true;
    },

    getSiloMarkerColor(siloId) {
      if (this.hasActiveAlarm(siloId)) return "icons/silo-marker-red.png";
      return "icons/silo-marker-green.png";
    },
  },

  computed: {
    ...mapState("silos", ["silos"]),
    ...mapGetters("alarms", ["hasActiveAlarm"]),
  },

  async created() {
    await this.getAlarmActives();
    await this.getSilos();
  },
};
</script>

<style lang="sass">
.siloChooseRow
  padding: 1.3rem
  border-radius: 5px
  border: 2px solid gray
  margin-top: .5rem
  font-size: 1.2rem

.siloChooseRow:hover
  background: #9c9c9c30
</style>